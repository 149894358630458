<template>
    <aside class="inspo-box">
        <ReviewsAd v-if="variation === 'reviews'" v-bind="adAttrs" />
        <RatingsAd v-else-if="variation === 'ratings'" v-bind="adAttrs" />
        <PlaceListsAd v-else-if="variation === 'lists'" v-bind="adAttrs" />
        <ReactionsAd v-else-if="variation === 'reactions'" v-bind="adAttrs" />
        <SignupAd v-else-if="variation === 'default'" v-bind="adAttrs" />
        <SignupAd v-else v-bind="adAttrs" />
    </aside>
</template>

<script setup>
import RatingsAd from '@/vue/components/ads/internal/community/RatingsAd.vue'
import ReviewsAd from '@/vue/components/ads/internal/community/ReviewsAd.vue'
import PlaceListsAd from '@/vue/components/ads/internal/community/PlaceListsAd.vue'
import ReactionsAd from '@/vue/components/ads/internal/community/ReactionsAd.vue'
import SignupAd from '@/vue/components/ads/internal/community/SignupAd.vue'

defineProps({
    variation: {
        type: String,
        required: false,
        default: 'default',
    },
})

const adAttrs = {
    class: 'tw-max-h-64 sm:tw-max-h-none tw-overflow-hidden',
    cta: false,
}
</script>
