<template>
    <div v-if="data && data.html" :class="$style.wrapper" v-html="data.html"></div>
    <div v-else-if="ad.component" :class="$style.wrapper">
        <div class="tw-overflow-hidden tw-rounded-md">
            <component :is="ad.component" v-bind="ad.props"></component>
        </div>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>
<script setup>
import { defineAsyncComponent } from 'vue'
import BaseElement from './BaseElement.vue'

const ADS = {
    WebshopAd: defineAsyncComponent(
        () => import('@/vue/components/ads/internal/webshop/WebshopAd.vue'),
    ),
    PlaceListsAd: defineAsyncComponent(
        () => import('@/vue/components/ads/internal/community/PlaceListsAd.vue'),
    ),
    RatingsAd: defineAsyncComponent(
        () => import('@/vue/components/ads/internal/community/RatingsAd.vue'),
    ),
    ReviewsAd: defineAsyncComponent(
        () => import('@/vue/components/ads/internal/community/ReviewsAd.vue'),
    ),
    ReactionsAd: defineAsyncComponent(
        () => import('@/vue/components/ads/internal/community/ReactionsAd.vue'),
    ),
    SignupAd: defineAsyncComponent(
        () => import('@/vue/components/ads/internal/community/SignupAd.vue'),
    ),
}

let ad = {
    component: null,
    props: {},
}

const props = defineProps({
    ...BaseElement.props,
    data: {
        type: Object,
        required: false,
        default: null,
    },
})

if (props.data.internal && ADS[props.data.component_name]) {
    ad.component = ADS[props.data.component_name]
    ad.props = props.data.component_props
}
</script>

<style module>
.wrapper {
    padding: var(--element-padding);
}
</style>
