<template>
    <CarouselElement
        v-if="items.length"
        :items="items"
        :title="title"
        :description="description"
        :url="data?.url"
    />
</template>
<script setup>
import CarouselElement from './CarouselElement.vue'
import { computed, watch } from 'vue'
import CarouselImage from './CarouselImage.vue'
import CarouselImageSkeleton from './CarouselImageSkeleton.vue'
import BaseElement from './BaseElement.vue'
import CarouselReadMore from './CarouselReadMore.vue'

const props = defineProps({
    ...BaseElement.props,
    data: {
        type: Object,
        required: false,
        default: null,
    },
})

const items = computed(() => {
    if (props.data === null) {
        const skeletons = []
        for (let i = 0; i < (props.configuration.limit || 3); i++) {
            skeletons.push({
                component: CarouselImageSkeleton,
                props: { index: i },
            })
        }
        return skeletons
    }
    const components = props.data.items.map((article) => {
        return {
            component: CarouselImage,
            props: {
                caption: article.shortTitle || article.title,
                description: article.shortTitle ? article.title : article.excerpt,
                src: article.artworkSrc,
                srcSet: article.artworkSrcSet,
                href: article.permalink,
                icon: 'icon category large ' + article.icon,
            },
        }
    })
    if (props.data.moreTitle && props.data.url) {
        components.push({
            component: CarouselReadMore,
            props: {
                title: props.data.moreTitle,
                buttonText: props.data.moreButtonText,
                href: props.data.url,
            },
        })
    }
    return components
})
</script>
