<template>
    <AuthButton v-bind="$attrs" :provider="provider" />
</template>
<script>
import AuthButton from './AuthButton.vue'
export default {
    components: { AuthButton },
    props: {
        provider: {
            type: String,
            required: true,
        },
    },
}
</script>
