import { defineStore } from 'pinia'
import http from '../es6/src/utils/http.js'
import { getRemoteText } from '../es6/src/modules/locale/locale.js'
import { PopupBubble } from '../es6/src/modules/popup/popupBubble.ts'
import { fadeOut } from '../es6/src/utils/animation.js'
import { useLocaleStore } from './locale.js'

export const useCityStore = defineStore('city', {
    state: () => ({
        citySetToDefault: window.globalVars.citySetToDefault,
        cityDidChange: window.globalVars.cityDidChange,
        currentCityCode: window.globalVars.currentCityCode,
        cities: window.globalVars.cities || [],
    }),
    getters: {
        currentCity(state) {
            return state.cities.find((city) => city.code === this.currentCityCode)
        },
        isFrontPage() {
            return window.location.pathname === '/'
        },
        isCityFrontPage() {
            return window.location.pathname === this.currentCity.permalink
        },
    },
    actions: {
        formatNumber(val) {
            return Intl.NumberFormat(this.currentCity.defaultLocale.fullCode).format(val)
        },
        findCity(data) {
            return http.get('/city/find', data).then((res) => {
                return res.data || null
            })
        },
        findAndSetCity(data) {
            return this.findCity(data).then((city) => {
                if (city) {
                    this.setCity(city)
                }
                return city
            })
        },
        setCity(city) {
            this.currentCityCode = city.code
        },
        async checkBrowserLanguageSupport() {
            const localeStore = useLocaleStore()
            // Check if the current city has another default language and the user's browser supports this language
            const navigatorLanguages = navigator
                ? navigator.languages || (navigator.language ? navigator.language.split(' ') : null)
                : null

            if (
                !this.currentCity ||
                !navigatorLanguages ||
                localeStore.code === this.currentCity.defaultLocale.code
            ) {
                return
            }
            const languageAvailable = navigatorLanguages.some((lang) => {
                return (
                    lang.toLowerCase() === this.currentCity.defaultLocale.code ||
                    lang.toLowerCase().indexOf(this.currentCity.defaultLocale.code + '-') === 0
                )
            })
            if (!languageAvailable) return

            // Check if this page is available in the default locale
            const alternateLocaleLink = window.document.querySelector(
                `.locale-list a[rel='alternate'][hreflang='${this.currentCity.defaultLocale.code}']`,
            )
            if (!alternateLocaleLink) return

            // Check if we've already asked the user about this
            const sessionKey = 'alternate_locale_available_' + this.currentCity.code
            // if (window.sessionStorage.getItem(sessionKey)) return

            window.sessionStorage.setItem(sessionKey, 'true')

            // Get text translation in default locale
            const { page_available_in_locale, more_content_available_in_locale } =
                await getRemoteText(this.currentCity.defaultLocale.code, [
                    'page_available_in_locale',
                    'more_content_available_in_locale',
                ])

            let localeSelector = window.document.querySelector('#locale-selector .popup-trigger')
            const bubbleOptions = {
                top: localeSelector.offsetTop + localeSelector.offsetHeight,
                fixed: true,
                className: 'left content',
            }

            if (!localeSelector.offsetParent) {
                localeSelector = document.querySelector('#hamburger')
            }
            bubbleOptions.origin = localeSelector

            const isFrontPage = this.isFrontPage || this.isCityFrontPage

            const link = alternateLocaleLink.cloneNode()
            const icon = window.document.createElement('i')
            const defaultLocaleCode = this.currentCity.defaultLocale.code

            icon.className = `icon icon--flag icon--flag--lang-${defaultLocaleCode} middle`

            const text = window.document.createElement('span')

            text.innerText = isFrontPage
                ? more_content_available_in_locale
                : page_available_in_locale
            link.appendChild(icon)
            link.appendChild(text)

            bubbleOptions.content = link

            const globeBubble = new PopupBubble(bubbleOptions)

            localeSelector.addEventListener('click', function () {
                globeBubble.close()
            })

            window.setTimeout(function () {
                fadeOut(globeBubble.container, 500).then(() => {
                    globeBubble.close()
                })
            }, 10000)
        },
    },
})
