<template>
    <div
        style="position: absolute; top: 0; left: 0; right: 0; bottom: 0"
        class="d-flex authentication-form pretty-form"
        :class="[
            'authentication-form--view-' + view,
            { ['authentication-form--' + variation]: variation },
        ]"
    >
        <AuthInspoBox v-if="showInspo" :variation="variation" />
        <div class="d-flex flex-column p-3 ai-center flex-grow" style="max-width: 100%">
            <div style="width: 100%">
                <a
                    role="button"
                    class="weight-600 color-dark-gray d-flex ai-center"
                    v-if="canGoBack"
                    @click="$emit('back')"
                >
                    <i
                        class="icon--chevron-left icon--chevron-left--gray"
                        style="height: 14px; width: 9px"
                    ></i>
                    <span class="ml-1">{{ $t('authForm.back') }}</span>
                </a>
            </div>
            <div
                class="flex-grow d-flex flex-column ai-center jc-center"
                style="max-width: 331px; width: 100%"
            >
                <div class="text-center mb-4" :class="{ 'tw-hidden lg:tw-block': !canGoBack }">
                    <img
                        class="tw-mx-auto tw-hidden lg:tw-block"
                        src="/media/img/logo/logo-dark-blue.svg"
                        width="226"
                    />
                    <span class="d-hidden-md-up">
                        <img
                            class="mb-1 d-block mx-auto"
                            src="/media/img/logo/logo-dark-blue.svg"
                            width="169"
                        />
                    </span>
                    <span class="block color-dark-slate-blue weight-700 text-md">
                        {{ tagline }}
                    </span>
                </div>
                <div style="width: 100%">
                    <slot />
                </div>
            </div>
            <p
                v-if="!user"
                class="legal explanation text-center"
                v-html="
                    $t('authForm.legal', {
                        terms: '/terms-of-service/',
                        privacy: '/privacy-policy',
                    })
                "
                style="max-width: 331px; width: 100%"
            ></p>
        </div>
    </div>
</template>
<script>
import AuthInspoBox from './AuthInspoBox.vue'
import { computed } from 'vue'
import { useCityStore } from '../../../../store/city.js'
export default {
    components: {
        AuthInspoBox,
    },
    props: {
        view: {
            type: String,
            required: true,
        },
        canGoBack: {
            type: Boolean,
            required: false,
            default: false,
        },
        showInspo: {
            type: Boolean,
            required: false,
            default: false,
        },
        variation: {
            type: String,
            required: false,
            default: null,
        },
        user: {
            type: Object,
            required: false,
            default: null,
        },
    },
    setup() {
        const cityStore = useCityStore()

        const tagline = computed(() => {
            return cityStore.currentCity?.fullTagline
        })

        return {
            tagline,
        }
    },
}
</script>
