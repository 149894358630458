export default {
    user: {
        settings: {
            social: {
                title: 'Sociala medier och länkar',
                description: 'Instagram, email etc.',
                choose_link_type: 'Välj typ',
            },
        },
    },
    placeWizard: {
        steps: {
            selectPlace: {
                title: 'Verksamhet',
                place_found: 'Det finns redan en verksamhet med liknande namn. Menade du detta?',
                places_found:
                    'Det finns redan {n} verksamheten med liknande namn. Menade du dessa?',
                none_skip: 'Nej, ingen av dessa',
            },
            premiumOwner: {
                title: 'Premium',
                description: 'Är du Thatsup Premium-kund?',
                what_is: 'Vad är',
                want_info: 'Vill ha information',
            },
            description: {
                title: 'Beskrivning',
                textarea_placeholder_new: 'Beskriv verksamheten. Max 500 tecken.',
                textarea_placeholder_edit: 'Föreslå en ändring...',
                textarea_label: 'Beskrivning',
                current_description_label: 'Befintlig beskrivning',
            },
            openingHoursOverview: {
                title: 'Öppettider',
                opening_hours: 'Fyll i öppettider',
                opening_hours_seasonal: 'Beroende på säsong etc.',
                no_opening_hours: 'Inga öppettider',
                always_open: 'Alltid öppet',
                no_opening_hours_confirm:
                    'Detta kommer att återställa dina ändringar av öppettiderna',

                opening_hours_seasonal_title: 'Markera de månader verksamheten är öppen.',
            },
            problem: {
                title: 'Problem',
                closed: 'Verksamheten har stängt, flyttat eller existerar inte',
                duplicate: 'Detta är en dubblett av en annan verksamhet på Thatsup',
                inappropriate: 'Denna verksamhet är olämplig',

                not_closed: 'Verksamheten har inte stängt',
                permanently_closed: 'Den har permanent stängt',
                temporarily_closed: 'Den har temporärt stängt',
                moved: 'Den har flyttat',
                nonexistent: 'Den existerar ej',

                other: 'Annat',

                duplicate_textarea_placeholder: 'Länk till dubblett på Thatsup',
                inappropriate_textarea_placeholder: 'Beskriv varför är det olämpligt...',
                other_textarea_placeholder: 'Beskriv problemet...',
            },
            general: {
                title: 'Allmän information',
                no_website_label: 'Har ingen hemsida',
            },
            editCategories: {
                title: 'Kategorier',
                addButton: 'Lägg till en kategori',
            },
            addCategories: {
                title: 'Kategorier',
                categories_label: 'Kategorier',
                add_category_label: 'Lägg till en kategori',
                add_category_placeholder: 'Pizza, Café, Frisör etc.',
                add_category_no_results_text: 'Kategorin finns inte. Försök söka på något annat.',
            },
            address: {
                title: 'Verksamhetens plats',
                autocomplete_placeholder: 'Upplandsgatan 7, Odenplan etc...',
                autocomplete_helper:
                    'Vet du inte den exakta adressen? Ange ett område eller gata i närheten eller välj',
                autocomplete_helper_manual_position_link: 'position manuellt',
                map_position: 'Position på kartan.',
                city: 'Stad',
                city_other: 'Annan stad',
            },
            summary: {
                title: 'Sammanfattning',
                nothing_changed: 'Du har inte ändrat något än.',
                removed: 'Borttagen',
            },
            userInfo: {
                title: 'Övrig information',
                description: 'För snabbare hantering, ange lite uppgifter om dig själv.',
                name_label: 'Ditt namn',
                phone_label: 'Ditt telefonnummer',
                email_label: 'Din mailadress',
                other_information_label: 'Övrig information',
                create_account_description: 'Skapa ett konto på Thatsup med mailadressen',
            },
            overview: {
                title: 'Översikt',
                general: {
                    title: 'Allmän information',
                    description: 'Telefon, hemsida etc.',
                },
                openingHoursOverview: {
                    title: 'Öppettider',
                    description: 'När har verksamheten öppet?',
                },
                address: {
                    title: 'Plats',
                    description: 'Adress och plats på karta.',
                },
                editCategories: {
                    title: 'Kategorier',
                    description: 'Prisklass, typ av kök etc.',
                },
                description: {
                    title: 'Beskrivning',
                    description: 'Föreslå ändringar.',
                },
                problem: {
                    title: 'Problem',
                    description: 'Verksamheten har stängt etc.',
                },
            },
            review: {
                title: 'Recensera',
                intro_title: 'Bli först med att recensera {name}!',
                button_yes: 'Skriv en recension',
                button_no: 'Nej tack',
                save_review: 'Spara recension',
                rating_text_placeholder: 'Vi vill veta vad du tycker!',
                body_placeholder: 'Skriv en recension',
                rating_1: 'Ajaj, katastrof!',
                rating_2: 'Det här är inte bra...',
                rating_3: 'Helt okej!',
                rating_4: 'Jag gillar det!',
                rating_5: 'Wow, det är grymt!',
            },
            thankYou: {
                title: 'Tack!',
                titleWithName: 'Tack {name}!',
                subTitleNewPlace:
                    'Vi kommer att granska ditt tips och publicera det så fort vi kan.',
                subTitleEditPlace: 'Tack för att du hjälper oss att förbättra Thatsup.',
                descriptionEditPlace:
                    'För att hålla vår information så korrekt som möjligt, uppskattar vi all hjälp från våra härliga läsare.',
                buttonText: 'Varsågod!',
                add_new_place: 'Lägg till en ny verksamhet',
            },
        },
        start: {
            title: 'Lägg till en verksamhet',
            title_edit: 'Redigera {placeName}',
            description:
                'Driver du verksamheten eller är du en användare som vill hjälpa oss att förbättra Thatsup?',
            owner: 'Ägare',
            user: 'Användare',
        },
        place: {
            name_label: 'Verksamhetens namn',
            website_label: 'Hemsida',
            phone_label: 'Telefon',
            facebook_label: 'Länk till Facebook-sida',
            instagram_label: 'Länk till Instagram',
            address_label: 'Adress',
        },
        common: {
            voluntary: 'Frivilligt',
            closed: 'Stängt',
            max: 'Max',
            yes: 'Ja',
            no: 'Nej',
            not_sure: 'Osäker',
            summary: 'Sammanfattning',
        },
        action: {
            next: 'Nästa',
            prev: 'Föregående',
            done: 'Klar',
            finish: 'Slutför',
            update_place: 'Uppdatera verksamhet',
            loading: 'Laddar...',
            updating: 'Uppdaterar...',
            creating: 'Skapar...',
            skip: 'Hoppa över',
        },
    },

    internal_ads: {
        webshop: {
            description:
                'Upptäck våra snygga kepsar och tote bags, med tryck inspirerade av restaurangmiljön.',
        },
        community: {
            reviews: {
                heading: 'Recensera och kommentera!',
                cta_label: 'Skapa ett konto',
                list_items: [
                    'Skapa & dela listor',
                    'Skriv recensioner',
                    'Slipp annonser',
                    'Helt gratis',
                ],
            },
            ratings: {
                heading: 'Betygsätt dina upplevelser!',
                cta_label: 'Skapa ett konto',
                list_items: [
                    'Skapa & dela listor',
                    'Skriv recensioner',
                    'Slipp annonser',
                    'Helt gratis',
                ],
            },
            lists: {
                heading: 'Skapa listor med dina favoriter!',
                cta_label: 'Skapa ett konto',
                list_items: [
                    'Skapa & dela listor',
                    'Skriv recensioner',
                    'Slipp annonser',
                    'Helt gratis',
                ],
            },
            reactions: {
                heading: 'Uttryck dig på ännu fler sätt!',
                cta_label: 'Skapa ett konto',
                list_items: [
                    'Skapa & dela listor',
                    'Skriv recensioner',
                    'Slipp annonser',
                    'Helt gratis',
                ],
            },
            signup: {
                heading: 'Skapa ett konto och få ut mer!',
                cta_label: 'Skapa ett konto',
                list_items: [
                    'Skapa & dela listor',
                    'Skriv recensioner',
                    'Slipp annonser',
                    'Helt gratis',
                ],
            },
        },
    },

    placeList: {
        edit: {
            title: 'Redigera',
            name_label: 'Listans namn',
            description_label: 'Listans beskrivning',
            public_label: 'Publik lista',
        },
        filters: {
            city: 'Stad',
            category: 'Kategori',
        },
        my_rating: 'Ditt betyg',
        user_rating: '{possessiveFirstName} betyg',
        paging_label: '{from}-{to} av {total}',
        my_lists: 'Dina listor',
        user_lists: '{possessiveFirstName} listor',
        place_search_placeholder: 'Sök verksamhet...',
        user_search_placeholder: 'Sök användare...',
        invite_user: 'Bjud in',
        cancel: 'Avbryt',
        save: 'Spara',
        delete_list: 'Radera lista',
        delete_list_confirm: 'Är du säker på att du vill radera listan? Detta går inte att ångra.',
        follow_list: 'Följ lista',
        following_list: 'Följer lista',
        unfollow_list: 'Avfölj',
        unfollow_list_confirm: 'Vill du avfölja denna lista?',
        creator_create_date: 'Skapade listan {dateAdded}',
        creator: 'Skapare',
        last_updated: 'Senast uppdaterad {dateUpdated}',
        list_by: 'Av',
        remove_owner: 'Ta bort',
        remove_owner_self: 'Lämna listan',
        remove_owner_self_confirm:
            'Är du säker på att du vill lämna listan? Detta går inte att ångra och du kommer inte längre kunna göra ändringar av listan.',
        list_empty: 'Listan är tom',
        common: {
            no: 'Nej',
            edit: 'Redigera',
            delete: 'Radera',
            share: 'Dela',
            report: 'Rapportera',
        },
        place_new: 'Ny!',
        place_closed: 'Nedlagt',
        place_add_new: 'Lägg till',
        list_create: 'Skapa lista',
        list_edit: 'Redigera lista',
        list_add_remove: 'Lägg till/Ta bort',
        list_users: 'Användare',
        map: 'Karta',
        tooltip: {
            public_switch:
                'En publik lista är åtkomlig för andra besökare på olika ställen på sajten, medan en icke publik inte kan hittas av andra besökare om du inte själv delar den exakta länken med dem.',
        },
        notes_input_placeholder: 'Dina anteckningar...',
    },

    authForm: {
        continue_with_facebook: 'Fortsätt med Facebook',
        continue_with_google: 'Fortsätt med Google',
        continue_with_email: 'Fortsätt med e-mail',

        email: 'E-mail',
        first_name: 'Förnamn',
        last_name: 'Efternamn',
        password: 'Lösenord',
        new_password: 'Nytt lösenord',
        confirm_password: 'Upprepa lösenord',
        email_suggestion: 'Är mailadressen verkligen rättstavad?',

        log_in: 'Logga in',

        continue: 'Fortsätt',
        send: 'Skicka',
        send_again: 'Skicka igen',
        send_instructions: 'Skicka instruktioner',

        or: 'Eller',

        back: 'Tillbaka',

        legal: 'Genom att skapa konto godkänner du Thatsups <a target="_blank" href="{terms}">Användarvillkor</a> och <a target="_blank" href="{privacy}">Integritetspolicy</a>.',

        forgot_password_question: 'Glömt lösenordet?',
        restore_password: 'Återställ lösenord.',
        forgot_password_sent: 'Instruktioner har skickats till {email}!',

        not_you_question: 'Inte du?',
        log_out: 'Logga ut.',

        welcome_user: 'Välkommen {name}!',
        profile_link: 'Till din profil',
    },

    userPoll: {
        alternatives_title: 'Nominerade',
        vote_confirm_title: 'Vill du lägga din röst på:',
        vote_delete_confirm: 'Vill du ta bort din röst på {alternative}?',
        vote_confirm_yes: 'Ja, rösta!',
        vote_confirm_no: 'Nej, välj en ny',
        poll_not_started_title: 'Omröstningen startar',
        poll_not_started_date: '{date}',
        poll_has_ended_title: 'Omröstningen avslutades',
        poll_has_ended_date: '{date}',
        vote_not_verified_description:
            'För att din röst ska räknas måste du verifiera ditt konto innan omröstningens slut.',
        verify_account: 'Verifiera konto.',
        voted_title: 'Tack för din röst!',
        voted_description: 'Röstningen avslutas {endDate} och kort därefter utses en vinnare.',
        search_alternatives_label: 'Sök bland de nominerade',
        search_placeholder: 'Sök...',
        vote_cancel: 'Ångra röst',
        winner: 'Vinnare',
        share: 'Dela din röst',
    },

    review: {
        rating_text_placeholder: 'Vi vill veta vad du tycker!',
        rating_1: 'Ajaj, katastrof!',
        rating_2: 'Det här är inte bra...',
        rating_3: 'Helt okej!',
        rating_4: 'Jag gillar det!',
        rating_5: 'Wow, det är grymt!',

        thank_you_title: 'Tack för din recension!',
        thank_you_title_rating: 'Tack för ditt betyg!',
        add_review: 'Lägg till recension',
        write_new: 'Skriv en ny',
        update_review: 'Uppdatera recension',
        write_a_review: 'Skriv en recension',
        autosaved_review: 'Autosparad recension',

        content_reminder:
            'T\u00e4nk p\u00e5 att det \u00e4r viktigt att skriva en \u00e4rlig recension. Om du p\u00e5 n\u00e5got s\u00e4tt \u00e4r kopplad till f\u00f6retaget s\u00e5 b\u00f6r du avst\u00e5 fr\u00e5n att recensera.',
        content_reminder_honesty_pays: '\u00c4rlighet varar l\u00e4ngst!',
        see_our_guidelines_here: 'Se våra riktlinjer här.',

        upload_photo_button: 'Ladda upp en bild',

        image_caption_placeholder: 'Bildtext...',
        image_delete_confirm: 'Vill du ta bort bilden?',

        index: {
            title: 'Vad vill du recensera?',
            search_placeholder: 'Sök verksamhet...',
            finding_location: 'Hittar din position...',
            finding_nearby_businesses: 'Hittar närliggande verksamheter...',
            nearby_businesses: 'Verksamheter i närheten',
        },
    },

    PlaceRateBox: {
        my_rating: 'Ditt betyg',
        write_review: 'Recensera',
        add_review: 'Lägg till recension',
        view_review: 'Se recension',
        add_photos: 'Lägg till bilder',
        delete_rating: 'Ta bort betyg',
        rate_cta: 'Betygsätt',
        rating_saved: 'Ditt betyg sparades!',
    },

    SearchAutocomplete: {
        begin_cta: 'Börja din sökning',
        term_label: 'Vad söker du?',
        term_placeholder: 'Guider, kategorier, verksamheter mm...',
        term_placeholder_small: 'Vad söker du?',
        location_label: 'Var?',
        location_placeholder: 'Stadsdel, adress...',
        near_me_label: 'Nära mig',
        near_me_hitwords: [
            'nära mig',
            'nära min position',
            'nära min plats',
            'min position',
            'min plats',
        ],
        guide_subtitle: 'Guide',
        groups: {
            history: 'Tidigare sökningar',
            recommendations: 'Rekommenderat',
        },
        term_catch_all_label: 'Visa alla resultat för “{term}”',
        term_catch_all_label_more: 'Visa fler resultat för “{term}”',
    },

    userMessageFloater: {
        try_me_cta: '🤖 Jag är Thatsups chatbot – testa mig!',
    },

    explore: {
        sorting: {
            label: 'Sortering',
            relevance: 'Relevans',
            rating: 'Betyg',
            alphabetical: 'A-Ö',
            distance: 'Avstånd',
            review_count: 'Antal recensioner',
        },
        filter: {
            label: 'Filtrera',
            distance_label: 'Avstånd',
            distance_number: '{number} m',
            open_label: 'Visa filter',
            close_label: 'Stäng filter',
            apply_label: 'Tillämpa',
        },
        place: {
            call_cta: 'Ring',
            website_cta: 'Hemsida',
            read_more_cta: 'Läs mer',
        },
        map: {
            my_location_label: 'Min position',
            search_in_area_label: 'Sök i området',
            expand: 'Förstora karta',
            contract: 'Förminska karta',
            show_map: 'Visa karta',
        },
    },
    pagination: {
        next: 'Nästa',
        previous: 'Föregående',
        next_page: 'Nästa sida',
        previous_page: 'Föregående sida',
        from_to: '{from}-{to}',
        from_to_total: '{from}-{to} av {total}',
        back: 'Bakåt',
        arrow_right: 'Högerpil',
        arrow_left: 'Vänsterpil',
    },

    elements: {
        user_action: {
            user_reviewed: 'Skrev en recension',
            place_list_updated: 'Uppdaterade en lista',
            place_list_created: 'Skapade en lista',
        },
    },

    common: {
        show_more: 'Visa fler',
        show_more_text: 'Visa mer',
        show_all: 'Visa alla',
        back: 'Tillbaka',
        cancel: 'Avbryt',
        website: 'Hemsida',
        phone_number: 'Telefonnummer',
        reviews: 'Recensioner',
        add_link: 'Lägg till länk',
        publish: 'Publicera',
        settings: 'Inställningar',
        notifications_short: 'Notiser',
        notifications: 'Notifikationer',
        close: 'Stäng',
        save: 'Spara',
        login: 'Logga in',
        logout: 'Logga ut',

        confirm: {
            title: 'Är du säker?',
            yes: 'Ja',
            no: 'Nej',
            cancel: 'Avbryt',
        },

        dirty_confirm: {
            title: 'Du har osparade ändringar',
            description: 'Är du säker på att du vill lämna sidan?',
            stay: 'Stanna kvar',
            leave: 'Lämna',
        },
    },
    place: {
        sponsored: 'Sponsrat',
        website_label: 'Hemsida',
    },

    placeAutocomplete: {
        placeholder: 'Sök verksamhet...',
    },

    popup: {
        userPlaceInteraction: {
            visited: {
                title: 'Varit här',
            },
            'want-to-visit': {
                title: 'Vill gå hit',
            },
        },
        reviewReaction: {
            title: 'Reaktioner',
        },
    },

    notifications: {
        show_all: 'Visa alla notiser',
    },

    mobileUserMenu: {
        user_profile: 'Profil',
        user_lists: 'Listor',
        user_messages: 'Meddelanden',
        user_feed: 'Flöde',
    },

    articleSuggestion: {
        title: 'Skapa event',
        name_label: 'Namn på eventet',
        name_placeholder: 'Namn eller rubrik på eventet...',
        artwork_label: 'Bild',
        artwork_upload_button: 'Ladda upp en bild',
        artwork_byline_label: 'Byline',
        artwork_byline_description: 'Vem som tagit bilden eller var den kommer ifrån',
        artwork_copyright_waiver:
            'Jag intygar att jag har rätt att publicera den här bilden och förstår att jag bär ansvaret för publiceringen av den. Thatsup bär inget ansvar vid eventuella brutna copyright-regler.',
        dates_label: 'När?',
        dates_description: 'Du kan välja ett eller flera datum',
        date_and_x_more_dates: '{date} och {count} till',
        location_label: 'Var?',
        location_placeholder: 'Plats eller adress...',
        description_label: 'Beskrivning',
        description_placeholder: 'Beskriv eventet...',
        category_label: 'Kategori',
        category_placeholder: 'Välj en kategori...',
        website_label: 'Officiell hemsida',
        website_placeholder: 'Länk till eventet...',
        ticket_url_label: 'Biljettlänk',
        ticket_url_placeholder: 'Länk till biljetter...',
        ticket_price_label: 'Biljettpris',
        ticket_price_placeholder: 'Pris på biljetter...',
        facebook_url_label: 'Facebook-event',
        facebook_url_placeholder: 'Länk till Facebook-event...',
        email_label: 'Din mailadress',
        email_placeholder: 'Din mailadress...',
        is_organizer_label: 'Jag är arrangör',
        organizer_name_label: 'Ditt namn',
        organizer_name_placeholder: 'Ditt namn...',
        organizer_phone_label: 'Ditt telefonnummer',
        organizer_phone_placeholder: 'Ditt telefonnummer...',
        optional_label: 'Frivilligt',
        submit_button: 'Skicka',
        other_information_label: 'Övrig information',
        other_information_placeholder: 'Övrig information...',

        thank_you_title: 'Tack för ditt tips 🌟',
        thank_you_description:
            'Tack för ditt tips! Vi strävar alltid efter att bli bättre och ditt engagemang betyder mycket för oss. Vi går igenom alla tips så fort vi kan, men däremot har vi tyvärr inte alltid möjlighet att återkomma med svar då vi får in så många tips. Vi hoppas att du har överseende med detta och ser fram emot att få fler tips från dig framöver.',
        to_home: 'Till startsidan',
        write_new: 'Föreslå ett till',
    },

    datepicker: {
        firstDayOfWeek: 1,
        dayNames: ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'],
        monthNames: [
            'Januari',
            'Februari',
            'Mars',
            'April',
            'Maj',
            'Juni',
            'Juli',
            'Augusti',
            'September',
            'Oktober',
            'November',
            'December',
        ],
    },
}
