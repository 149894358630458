<template>
    <AuthLayout
        :can-go-back="!user && view !== 'start'"
        :user="user"
        :view="view"
        :variation="currentVariation"
        :show-inspo="isShown && (!user || didAuth)"
        @back="onBack"
    >
        <div class="flex-grow" v-if="isShown">
            <div v-if="!user">
                <p v-if="error && error.message" class="explanation error text-center">
                    {{ error.message }}
                </p>
                <div v-if="view === 'start'">
                    <OAuthButton provider="facebook" @click.prevent="authApp('facebook')" />
                    <OAuthButton provider="google" @click.prevent="authApp('google')" />
                    <span class="type-separator">{{ $t('authForm.or') }}</span>
                    <AuthButton provider="email" @click="onAuthButtonClick" />
                </div>
                <div v-else-if="view === 'email'">
                    <EmailForm
                        :error="error"
                        :forgot-password-code="forgotPasswordCode"
                        v-model="editedUser"
                        @error="handleResponse"
                        @cancel="onCancel"
                        @step="resetError"
                    />
                </div>
            </div>
            <WelcomeUser v-else :user="user" :did-auth="didAuth" />
        </div>
    </AuthLayout>
</template>
<script>
import AuthLayout from './layouts/AuthLayout.vue'
import EmailForm from './components/EmailForm.vue'
import OAuthButton from './components/OAuthButton.vue'
import AuthButton from './components/AuthButton.vue'
import WelcomeUser from './components/WelcomeUser.vue'
import EventBus from '../../Shared/EventBus.js'
import { useUserStore } from '../../../store/user.js'
import { authentication } from '../../../es6/src/modules/authentication/authentication.js'

const VARIATIONS = [
    'default',
    'lists',
    'reactions',
    'logged-out',
    'reviews',
    'ratings',
    'login',
    'adblock',
]

export default {
    name: 'AuthForm',
    components: {
        AuthLayout,
        EmailForm,
        OAuthButton,
        AuthButton,
        WelcomeUser,
    },
    setup() {
        const userStore = useUserStore()

        return { userStore }
    },
    data() {
        return {
            isShown: false,
            view: 'start',
            didAuth: false,
            variation: 'default',
            authResponse: null,
            editedUser: {},
            forgotPasswordCode: null,
        }
    },
    computed: {
        currentVariation() {
            return VARIATIONS.indexOf(this.variation) === -1 ? 'default' : this.variation
        },
        error() {
            return this.authResponse ? this.authResponse.error : null
        },
        user() {
            return this.userStore.user
        },
        // ...mapState(['user']),
    },
    methods: {
        onAuthButtonClick() {
            this.view = 'email'
            this.resetError()
        },
        onBack() {
            this.view = 'start'
            this.resetError()
        },
        onCancel() {
            this.view = 'start'
            this.resetError()
        },
        show(options = {}) {
            this.isShown = true
            this.view = 'view' in options ? options.view : 'start'
            this.variation = 'variation' in options ? options.variation : 'default'
            this.authResponse = options.authResponse
            if (options.authResponse) {
                this.handleResponse(options.authResponse)
                if (!('view' in options) && options.authResponse.data.provider === 'email') {
                    this.view = 'email'
                }
                if (options.authResponse.data.data) {
                    this.forgotPasswordCode = options.authResponse.data.data.forgot_password_code
                }
            }
            if (options.user) {
                this.editedUser = { ...this.editedUser, ...options.user }
            }
        },
        hide() {
            this.isShown = false
        },
        authApp(provider) {
            return authentication.authApp(provider)
        },
        handleResponse(data) {
            this.authResponse = data
            this.editedUser = data.data.user
        },
        resetError() {
            if (this.authResponse) {
                this.authResponse.error = null
            }
        },
    },
    mounted() {
        this.editedUser = this.user ? { ...this.user } : {}
        // In case we didn't mount before the show event was emitted, we do it here
        if (!!authentication.showOptions) {
            this.show(authentication.showOptions)
        }

        EventBus.$on('thatsup:auth:show', this.show)
        EventBus.$on('thatsup:auth:hide', this.hide)
        EventBus.$on('thatsup:auth:failure', this.handleResponse)
        EventBus.$on('thatsup:auth:success', (data) => {
            this.didAuth = true
            this.handleResponse(data)
        })
    },
}
</script>
