<template>
    <label
        for="q"
        ref="searchLabel"
        class="tw-cursor-pointer"
        @click="searchAutoComplete.open"
        :title="$t('SearchAutocomplete.begin_cta')"
    >
        {{ searchAutoComplete.term || $t('SearchAutocomplete.begin_cta') }}
    </label>
</template>
<script setup>
import { useSearchAutocompleteStore } from '../../../store/searchAutocomplete.js'
import { onMounted, onBeforeUnmount, ref } from 'vue'

const searchAutoComplete = useSearchAutocompleteStore()

// const bindCommandKEvent = (event) => {
//     if (event.key === 'k' && event.metaKey) {
//         event.preventDefault()
//         searchAutoComplete.open()
//     }
// }

// onMounted(() => {
//     window.addEventListener('keydown', bindCommandKEvent)
// })

// onBeforeUnmount(() => {
//     window.removeEventListener('keydown', bindCommandKEvent)
// })
</script>
