<template>
    <CarouselElement v-if="items.length" :items="items" :title="title" :description="description" />
</template>
<script setup>
import CarouselElement from './CarouselElement.vue'
import { computed } from 'vue'
import PlaceCardLarge from './../../../../Shared/components/PlaceCardLarge.vue'
import CarouselImageSkeleton from './CarouselImageSkeleton.vue'
import CarouselReadMore from './CarouselReadMore.vue'
import BaseElement from './BaseElement.vue'

const props = defineProps({
    ...BaseElement.props,
    data: {
        type: Object,
        required: false,
        default: null,
    },
})

const items = computed(() => {
    if (props.data === null) {
        const skeletons = []
        for (let i = 0; i < (props.configuration.limit || 3); i++) {
            skeletons.push({
                component: CarouselImageSkeleton,
                props: { index: i },
            })
        }
        return skeletons
    }
    const places = props.data.items.map((place) => {
        return {
            component: PlaceCardLarge,
            props: {
                place,
            },
        }
    })
    if (props.data.moreTitle && props.data.url) {
        places.push({
            component: CarouselReadMore,
            props: {
                title: props.data.moreTitle,
                href: props.data.url,
                aspectRatio: '16 / 9',
            },
        })
    }
    return places
})
</script>
