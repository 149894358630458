<template>
    <div :class="$style.carouselElement">
        <ElementTitle :title="title" :description="description" :url="url">
            <template #after>
                <div :class="$style.pagination">
                    <button
                        :class="[
                            $style.paginateButton,
                            $style.paginateButtonLeft,
                            { [$style.paginateButtonHidden]: !canGoBack },
                        ]"
                        :disabled="!canGoBack"
                        @click="goBack"
                        type="button"
                        :title="$t('pagination.previous')"
                    >
                        <img
                            src="/media/img/streamline-icons/circle-small-button.svg"
                            :alt="$t('pagination.arrow_left')"
                        />
                    </button>
                    <button
                        v-if="items.length > itemsToShow"
                        :class="[
                            $style.paginateButton,
                            { [$style.paginateButtonDisabled]: !canGoForward },
                        ]"
                        :disabled="!canGoForward"
                        @click="goForward"
                        type="button"
                        :title="$t('pagination.next')"
                    >
                        <img
                            src="/media/img/streamline-icons/circle-small-button.svg"
                            :alt="$t('pagination.arrow_right')"
                        />
                    </button>
                </div>
            </template>
        </ElementTitle>

        <div :class="$style.wrapper">
            <DraggableList
                :items="items"
                :class="$style.carouselContainer"
                v-model:active="activeIndex"
            >
                <template v-slot="{ item, index }">
                    <li :class="$style.carouselItem">
                        <component :is="item.component" v-bind="item.props"></component>
                    </li>
                </template>
            </DraggableList>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import DraggableList from '../../../../Shared/components/DraggableList.vue'
import useViewport from '../../../../Shared/util/use-viewport'
import ElementTitle from './ElementTitle.vue'

const props = defineProps({
    title: String,
    description: String,
    url: {
        type: String,
        required: false,
        default: null,
    },
    items: Array,
    itemsPerSlide: {
        type: Number,
        required: false,
        default: 3,
    },
})

const activeIndex = ref(0)

const { width: windowWidth } = useViewport()

const itemsToShow = computed(() => {
    return windowWidth.value < 768 ? 1 : props.itemsPerSlide
})

const itemWidth = computed(() => {
    return 100 / itemsToShow.value + '%'
})

const canGoBack = computed(() => activeIndex.value > 0)
const canGoForward = computed(() => {
    return activeIndex.value < props.items.length - itemsToShow.value
})

function goBack() {
    activeIndex.value = Math.max(0, activeIndex.value - 1)
}

function goForward() {
    activeIndex.value = Math.min(props.items.length - 1, activeIndex.value + 1)
}
</script>

<style module>
.carouselElement {
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    color: #134154;
    padding: var(--element-padding);
}

.title {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 0.2rem;
}

.description {
    font-size: 16px;
    font-weight: 600;
}

.wrapper {
    overflow: hidden;
    padding: var(--element-padding);
}

.carouselContainer {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    padding-bottom: 5px;
}

.carouselItem {
    padding: 0 10px;
    flex: 0 0 100%;
    max-width: 100%;
}

@media screen and (max-width: 767px) {
    .carouselItem {
        flex: 0 0 calc(100% - 45px);
        max-width: calc(100% - 45px);
    }
}

@media screen and (min-width: 768px) {
    .carouselItem {
        flex: 0 0 v-bind(itemWidth);
        max-width: v-bind(itemWidth);
    }
}

.pagination {
    display: flex;
    gap: 16px;
}

@media screen and (max-width: 767px) {
    .pagination {
        display: none;
    }
}

.paginateButton {
    display: inline-flex;
    background: none;
    border: 0;
    padding: 0;
}

.paginateButtonHidden {
    opacity: 0;
}

.paginateButtonDisabled {
    opacity: 0.7;
}

.paginateButtonLeft {
    transform: rotate(180deg);
}
</style>
