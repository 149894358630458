<template>
    <div class="tw-@container">
        <div
            ref="componentRef"
            class="tw-relative tw-items-end tw-overflow-hidden tw-bg-dark-blue-600 tw-text-white @3xl:tw-flex"
        >
            <div
                class="tw-absolute tw-max-w-[15rem] tw-px-4 tw-py-4 @sm:tw-relative @md:tw-max-w-none @md:tw-px-10 @md:tw-pt-14 @3xl:tw-p-10 @3xl:tw-pr-0"
                :class="{
                    'tw-self-center': !cta,
                }"
            >
                <img
                    v-if="logo"
                    src="../assets/images/logo-white.svg"
                    alt="Thatsup logo"
                    width="79.5"
                    height="24"
                    class="tw-w-14 @3xl:tw-w-20"
                    ref="logoRef"
                />

                <div
                    ref="headingRef"
                    class="tw-mb-6 tw-text-xl tw-font-extrabold tw-leading-tight @md:tw-text-4xl @3xl:tw-text-4xl @3xl:tw-leading-tight"
                    :class="{
                        'tw-mt-6 @3xl:tw-mt-14': logo,
                    }"
                >
                    {{ headingText }}
                </div>

                <ul
                    ref="listItemsRef"
                    class="tw-relative tw-flex tw-flex-col tw-gap-x-3 tw-gap-y-2 tw-whitespace-nowrap tw-text-sm tw-font-semibold @sm:tw-gap-y-0 @sm:tw-text-base @md:tw-grid @md:tw-w-fit @md:tw-grid-cols-2"
                >
                    <li
                        v-for="item in listItems"
                        :key="item"
                        class="tw-flex tw-items-center tw-gap-1.5 @md:tw-gap-2.5"
                    >
                        <svg
                            width="10"
                            height="8"
                            viewBox="0 0 10 8"
                            class="tw-h-3.5 tw-w-3.5 tw-shrink-0 tw-rounded-full tw-p-0.5 tw-text-dark-blue-600 @md:tw-h-4 @md:tw-w-4"
                            :class="{
                                'tw-bg-orange-200': accentColor === 'orange',
                                'tw-bg-blue-200': accentColor === 'blue',
                                'tw-bg-green-200': accentColor === 'green',
                                'tw-bg-red-200': accentColor === 'pink',
                            }"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.77534 7.22614L0.699219 4.15002L1.64497 3.20439L3.77534 5.33477L8.35572 0.754395L9.30147 1.70002L3.77534 7.22614Z"
                                fill="currentColor"
                            />
                        </svg>

                        <span>{{ item }}</span>
                    </li>
                </ul>

                <!-- Button -->
                <a
                    v-if="cta"
                    @click="handleCtaClick"
                    role="button"
                    class="tw-group tw-relative tw-my-6 tw-inline-flex tw-items-center tw-justify-between tw-gap-2 tw-overflow-hidden tw-rounded-full tw-bg-blue-500 tw-py-1.5 tw-pl-4 tw-pr-2 tw-text-sm tw-text-white @3xl:tw-my-10"
                    ref="buttonRef"
                >
                    <span class="tw-font-bold tw-text-white">{{ ctaLabel }}</span>

                    <span
                        class="tw-h-7 tw-w-7 tw-rounded-full tw-bg-blue-100 tw-p-1 tw-text-blue-500"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </span>
                </a>
            </div>

            <!-- Large image -->
            <div
                v-if="images.large"
                class="tw-relative tw-right-0 -tw-mb-14 tw-hidden @md:-tw-mb-20 @md:tw-block @3xl:tw-mb-0 @3xl:tw-w-full @3xl:tw-max-w-[470px]"
            >
                <img
                    :src="images.large"
                    :srcset="images.large2x ? `${images.large} 1x, ${images.large2x} 2x` : ''"
                    :alt="imageAlt"
                    class="tw-w-full"
                />
            </div>

            <!-- Medium image -->
            <div
                v-if="images.medium"
                class="tw-absolute tw-bottom-0 tw-right-0 tw-hidden @sm:tw-block @md:tw-hidden"
            >
                <img
                    :src="images.medium"
                    :srcset="images.medium2x ? `${images.medium} 1x, ${images.medium2x} 2x` : ''"
                    :alt="imageAlt"
                />
            </div>

            <!-- Small image -->
            <div v-if="images.small" class="tw-float-right tw-block @sm:tw-hidden">
                <img
                    :src="images.small"
                    :srcset="images.small2x ? `${images.small} 1x, ${images.small2x} 2x` : ''"
                    :alt="imageAlt"
                    class="tw-max-w-none"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
/**
 * This component is not meant to be used directly, but rather as a base for other components.
 */

import { onMounted, ref } from 'vue'
import { auth } from '../../../../../es6/src/modules/authentication/authentication.js'

const props = defineProps({
    accentColor: {
        type: String,
        default: 'blue',
        validator(value: string): boolean {
            return ['orange', 'blue', 'green', 'pink'].includes(value as string)
        },
    },
    cta: {
        type: Boolean,
        default: true,
    },
    logo: {
        type: Boolean,
        default: false,
    },
    redirectTo: {
        type: String,
        default: '',
    },
    images: {
        type: Object,
        default: () => ({
            large: '',
            large2x: '',
            medium: '',
            medium2x: '',
            small: '',
            small2x: '',
        }),
    },
    imageAlt: {
        type: String,
        default: 'Banner Image',
    },
    headingText: {
        type: String,
        required: false,
        default: '',
    },
    ctaLabel: {
        type: String,
        required: false,
        default: '',
    },
    listItems: {
        type: Array,
        required: false,
        default: () => [],
    },
})

// Emits
const emit = defineEmits(['ctaClick'])

// Refs
const logoRef = ref(null)
const buttonRef = ref(null)
const headingRef = ref(null)
const listItemsRef = ref(null)
const componentRef = ref(null)

function handleCtaClick() {
    emit('ctaClick')

    auth(() => {
        if (props.redirectTo) {
            window.location.href = props.redirectTo
        } else {
            window.location.reload()
        }
    })
}

// Animation setup
onMounted(() => {
    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.7,
    }

    headingRef.value.style.opacity = '0'

    if (logoRef.value) {
        logoRef.value.style.opacity = '0'
    }

    const listItems = listItemsRef.value.querySelectorAll('li')
    listItems.forEach((item, index) => {
        item.style.transform = 'translateX(-20px)'
        item.style.opacity = '0'
        item.style.transition = `transform 0.6s ${index * 0.1}s ease-out, opacity 0.6s ${index * 0.1}s ease-out`
    })

    if (buttonRef.value) {
        buttonRef.value.style.opacity = '0'
    }

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (logoRef.value) {
                    logoRef.value.style.transition =
                        'opacity 0.8s ease-out, transform 0.8s ease-out'
                    logoRef.value.style.opacity = '1'
                    logoRef.value.style.transform = 'translateY(0)'
                }

                headingRef.value.style.transition =
                    'opacity 0.8s 0.1s ease-out, transform 0.8s 0.1s ease-out'
                headingRef.value.style.opacity = '1'
                headingRef.value.style.transform = 'translateY(0)'

                listItems.forEach((item) => {
                    item.style.transform = 'translateX(0)'
                    item.style.opacity = '1'
                })

                if (buttonRef.value) {
                    buttonRef.value.style.transition = 'opacity 0.5s 1s ease-out'
                    buttonRef.value.style.opacity = '1'
                }

                observer.unobserve(entry.target)
            }
        })
    }, observerOptions)

    observer.observe(componentRef.value)
})
</script>
