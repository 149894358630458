<template>
    <ul class="user-menu-nav mobile-user-menu overlay-scroll tw-p-5">
        <li class="big-cta">
            <img
                src="/media/img/logo/logo-dark-blue.svg"
                alt="Thatsup Logo"
                width="180"
                height="53"
            />
            <span class="weight-700 d-block">
                {{ cityStore.currentCity.fullTagline }}
            </span>
        </li>
        <li class="mb-2">
            <button
                :title="$t('authForm.continue_with_facebook')"
                @click.prevent="facebookAuth"
                class="auth-button auth-button--facebook auth-button--w-icon auth-button--full"
            >
                <i class="icon--auth icon--auth-facebook"></i>
                {{ $t('authForm.continue_with_facebook') }}
            </button>
        </li>
        <li class="mb-2">
            <button
                :title="$t('authForm.continue_with_google')"
                @click.prevent="googleAuth"
                class="auth-button auth-button--google auth-button--w-icon auth-button--full"
            >
                <i class="icon--auth icon--auth-google"></i>
                {{ $t('authForm.continue_with_google') }}
            </button>
        </li>
        <li class="mb-2">
            <span class="auth-separator">{{ $t('authForm.or') }}</span>
        </li>
        <li class="mb-2">
            <a
                :title="$t('authForm.continue_with_email')"
                role="button"
                @click="auth(null, { view: 'email' })"
                class="auth-button auth-button--email auth-button--w-icon auth-button--full"
            >
                <i class="icon--auth icon--auth-email"></i>
                {{ $t('authForm.continue_with_email') }}
            </a>
        </li>
        <li class="sign-up-legal">
            <span
                v-html="
                    $t('authForm.legal', {
                        terms: routes.terms,
                        privacy: routes.privacyPolicy,
                    })
                "
            ></span>
        </li>
    </ul>
</template>
<script setup>
import { useRoutesStore } from '../../../store/routes.js'
import { useUserStore } from '../../../store/user.js'
import { useCityStore } from '../../../store/city.js'
import { auth } from '../../../es6/src/modules/authentication/authentication.js'

const routesStore = useRoutesStore()
const userStore = useUserStore()
const cityStore = useCityStore()
const routes = routesStore.routes
const requestUri = encodeURIComponent(window.location.pathname + window.location.search)

const facebookAuth = () => {
    window.location.href = '/auth/app/facebook/?redirect=' + requestUri
}

const googleAuth = () => {
    window.location.href = '/auth/app/google/?redirect=' + requestUri
}
</script>
